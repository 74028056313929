<template>
  <div  class="about border rounded-lg">
    <center>Welcome Visitors</center>
        <p/><p/>
      This website was created to improve access to Andrea Rossi's ongoing conversations in 
      the <i>Journal of Nuclear Physics</i> ("JONP"), 
      where he has been discussing his LENR work since 2010. 
      <p/>
      It is updated several times an hour by 
      webcrawling the JONP,
      downloading the comments posted within the various articles and sorting them
      in date order. Rossi's answers are paired with each visitor's question, 
      when possible.
      <p/>
      My hope is that users will find this a convenient way to follow the discussion there. 
      Should you wish to
      post a question to Rossi yourself, please follow this link to the 
      <b-link :href="postingLink" target="_blank">comments form on the JONP</b-link>.
      <p/>
      kind regards,<br/>Eric Woudenberg<br/>eaw@woudy.org
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    postingLink: {
      type: String,
      default: ''
    },
  },
}
</script>

<style lang="less">
.about {
  text-align: left;
  padding: 20px;
  margin: 40px auto;
  width: 50%;
  min-width: 300px;
  border-color: var(--user-widget-border)!important;
  color: var(--user-widget-border) !important;
  border-width: 1px !important;
}
.about a {
  color: var(--user-widget-border) !important;
  font-weight: bold;
}
</style>
