<template>
  <div 
    id="app">
    <top v-model="settings" />
  </div>
</template>

<script>
import Top from './components/Top.vue'

export default {
  name: 'App',
  components: {
    Top,
  },
  data() {
    return {
      settings: {},
    }
  },
  mounted() {
    if (localStorage.settings) {
      this.settings = JSON.parse(localStorage.settings)
    } else {
      this.settings = {darkTheme: false}
    }
  },
  watch: {
    settings: {
      handler(newSettings) {
        localStorage.settings = JSON.stringify(newSettings)
        this.setUserStyle()
      },
      deep: true,
    }
  },  
  computed: {
  },
  methods: {
   userStyle () {
      return {
        '--user-background-color': this.settings.darkTheme ? "#162328": "#d0b88c",
        '--user-answer-color': this.settings.darkTheme ? "#f4c26b" : "black",
        '--user-question-color': this.settings.darkTheme ? "#73eeb6" : "black",
        '--user-answer-background': this.settings.darkTheme ? "#1f170c" : "#ffcc99",
        '--user-question-background': this.settings.darkTheme ? "#041713" : "white",
        '--user-group-background': this.settings.darkTheme ? "#0e3208": "#c1b18d",
        '--user-widget-background': this.settings.darkTheme ? "#121d20": "white",
        '--user-widget-color': this.settings.darkTheme ? "white" : "black",
        '--user-widget-border': this.settings.darkTheme ? "#28a745" : "black",
        '--user-title-color': this.settings.darkTheme ? "#42b983" : "black",
        '--user-daterange-selected-color': this.settings.darkTheme ? "364348" : "white",
      }
    },
    setUserStyle() {
      this.removeOld()
      let style = document.createElement('style')
      style.id='body-style-id'
      let sheet = ''
      let uStyle = this.userStyle()
      for (let cvar in uStyle) {
        sheet += `${cvar}: ${uStyle[cvar]}; `
      }
      style.innerHTML = `body {${sheet}}`;
      document.body.appendChild(style);
    },
    removeOld() {
      let sheetToBeRemoved = document.getElementById('body-style-id')
      if (sheetToBeRemoved) {
        let sheetParent = sheetToBeRemoved.parentNode
        sheetParent.removeChild(sheetToBeRemoved)
      }
    }
  },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 30px;
}

body {
    background-color: var(--user-background-color);
}


</style>
