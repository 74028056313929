<template>
  <div>
    <b-container>
      <div style="margin-bottom:20px;">
      </div>
      <h1>Rossi Blog Reader</h1>

      <!-- Floating stats -->
      <span 
        class="border rounded-lg loader-label" 
        > {{ linesLoaded }} / {{ total }}
      </span>
      <p/>

      <!-- Search box -->
      <div style="text-align: left; position: relative">
        <span class="m-2 body-elem">Search:</span>
        <b-form-input 
          class="m-2 search-box"
          v-model="searchString"
          placeholder='Enter search terms or a "search phrase"'
          debounce="500"
          :trim="true"
        />
        <b-button 
          class="m-2 clear-button" 
          @click="clearTextAndSearch" 
          :variant="buttonVariant">Clear</b-button>
      </div>

      <!-- Date picker -->
      <div style="text-align: left;">
        <span class="m-2 body-elem" >Date Range:</span>
        <date-range-picker
          class="m-2"
          ref="picker"
          :showDropdowns="true"
          :append-to-body="false"
          opens="right"
          :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
          :autoApply="false"
          v-model="dateRange"
          @update="reSearch"
          :linkedCalendars="false">
          <template v-slot:input="picker">
              <span style="min-width: 300px; white-space: nowrap; ">
                {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
              </span>
          </template>
        </date-range-picker>
        <b-button 
          style="display: inline;"
          class="m-2 clear-button" 
          @click="clearDateAndSearch" 
          :variant="buttonVariant">Clear
        </b-button>

        <!-- Sort radio buttons -->
        <span>
          <b-form-group class="body-elem" v-slot="{ ariaDescribedby } " style="display: inline; margin-bottom: -20px;margin-left: 20px">
            <b-form-radio class="body-elem" v-model="ascendingSort" :aria-describedby="ariaDescribedby" :value="true" style="display: block;">Ascending</b-form-radio>
            <b-form-radio class="body-elem" v-model="ascendingSort" :aria-describedby="ariaDescribedby" :value="false" style="display: block;">Descending</b-form-radio>
          </b-form-group>
        </span>

      </div>

      <b-list-group>
        <blog-entry v-for="entry in searchResults" :key="entry.index" :entry="entry"/>
      </b-list-group>

    </b-container>
    
    <infinite-loading :identifier="kickScrollerToPullAgain" @infinite="infiniteHandler">
      <template slot="no-more">No more results</template>
      <template slot="no-results">No results</template>
    </infinite-loading>
  </div>
</template>

<script>

const URI = "http://rossilivecat.com:13760/rossi"
// const URI = "http://localhost:9200/rossi"

import BlogEntry from '@/components/BlogEntry.vue'
import InfiniteLoading from 'vue-infinite-loading'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'BlogReader',
  components: {
    BlogEntry,
    InfiniteLoading,
    DateRangePicker,
  },
  props: {
    darkTheme: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      searchString: '',
      searchResults: [],
      currentLastId: 0,
      dateRange: {},
      kickScrollerToPullAgain: 0,
      linesLoaded: 0,
      total: '',
      ascendingSort: false,
      chunkSize: 20,
    }
  },
  mounted() {
    this.clearDateAndSearch()
  },
  watch: {
    searchString() {this.reSearch()},
    ascendingSort() {this.reSearch()},
  },
  computed: {
    buttonVariant() {
      return this.darkTheme ? "outline-success" : "outline-warning"
    }
  },
  methods: {
    clearDateAndSearch() {
      this.clearDate()
      this.reSearch()
    },
    clearTextAndSearch() {
      this.searchString = ''
      this.reSearch()
    },
    clearDate() {
      this.dateRange = {
            // Rossi's blog starts in 2010
            startDate: new Date('2010-03-03T08:00:00Z'),
            endDate: new Date()
          }
    },
    reSearch() {
      this.searchResults = []
      this.linesLoaded = 0
      this.chunkSize = 20
      this.currentLastId = this.ascendingSort ? 0 : 1e10
      this.kickScrollerToPullAgain++
    },
    infiniteHandler(state) {
      this.findSearchResults()
        .then(size => {
          if (size > 0) {
            this.chunkSize = Math.min(256, Math.trunc(this.chunkSize * 1.618))
            state.loaded()
          } else {
            state.complete()
          }
      })
    },    
    query() {
      let q = {
        size : this.chunkSize,
        sort : [{ index : 
                    {order : this.ascendingSort ? "asc" : "desc"}}],
        query: { bool: 
                   {must: [], 
                    filter: []}},
      }
      let query_phrase = this.searchString.replace('"', '').trim()

      if (query_phrase) {
        let match = { 
          "multi_match": {
            fields: [
              "body", "author",
              "question.body", "question.author"], 
            query: query_phrase}
        }
        q.query.bool.must.push(match)

        let is_match_phrase = this.searchString.startsWith('"')
        if (is_match_phrase) {
          match.multi_match.type = "phrase"
        }
      }
      let comparison = this.ascendingSort ? "gt" : "lt" 
      q.query.bool.filter.push({ "range": { "index": { [comparison]: this.currentLastId }}})
      q.query.bool.filter.push({ "range": { "date": { "gte": this.dateRange.startDate, 
                                                      "lte": this.dateRange.endDate}}})
      return q
    },
    findSearchResults() {
      let uri = `${URI}/_search`
      let params = {source: this.query(), source_content_type: 'application/json'}
      
      return this.$http
        .get(uri, {params})
        .then(result => {
          //console.log('results')

          let results = result.data.hits.hits.map(hit => hit._source)
          this.searchResults.push(...results)

          // On first load, emit the URL-link to the posting form on Rossi's blog
          if (!this.total) {
            let respondUrl = results[0].original_url.replace(/#.*/, '#respond')
            this.$emit('postingLinkFound', respondUrl)
          }

          // If we have results, or this is our first search, update the counters.
          if (results.length || this.linesLoaded == 0) {

            // Correct the foreshortened-due-to-scrolling total by the amount we have already loaded
            if (result.data.hits.total.relation == 'eq') {
              result.data.hits.total.value += this.linesLoaded
            }
            let prefix = {gte: '>', lte: '<', eq: ''}[result.data.hits.total.relation] || ''
            this.total = `${prefix}${result.data.hits.total.value}`
            this.linesLoaded += results.length
            if (results.length) {
              this.currentLastId = results.slice(-1)[0].index
            }
          }
          return results.length
        })
    },
  }
}
</script>

<style lang="less">

.dropdown-menu, .calendar-table, th, tr, td, th.month, select.monthselect, input.yearselect {
  background-color:var(--user-background-color) !important;
  color:var(--user-widget-color) !important;
}

td.in-range {
  background-color: var(--user-daterange-selected-color) !important;
}

h1,h2,h3,h4,h5,h6 {
    color: var(--user-title-color);
}

.form-control,.form-control:focus {
  background-color: var(--user-widget-background) !important;
  color: var(--user-widget-color);
}

.loader-label {
  font-size: 10px;
  padding: 5px; 
  margin-top: 20px;
  margin-right: 21px;
  float: right;
  color:var(--user-widget-color);
  border-color:var(--user-widget-border) !important;
  position: sticky; 
  top: 0.25rem; 
  z-index:999;
}

.body-elem {
 color: var(--user-widget-color);
}

.search-box {
  width: 45%; 
  background-color: var(--user-widget-background);
  color: var(--user-widget-color); 
  display: inline; 
}

.clear-button {
  color: var(--user-widget-color);
  border-color: var(--user-widget-border);
}
</style>
