<template>
  <div>
    <div v-if="entry.question && !embed" class="border rounded-lg group-style">
      <blog-entry :entry="entry.question" :embed="true"/>
      <blog-entry :entry="entry" :embed="true"/>
    </div>
    <div v-if="!entry.question || embed" :class="{unembedded: !embed}">
      <b-list-group-item :class="blockClass" class="text-left rounded-lg m-2" >
        <p> 
          <b-link target="_blank" :href="entry.original_url">
            {{ date }} {{ entry.author}} 
          </b-link> 
        </p>
        <p v-html="entry.body"/>
      </b-list-group-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogEntry',
  props: {
    entry: {
      type: Object,
      default() {
        return {}
      }
    },
    embed: {
      type: Boolean,
      default: false,
    },
  },
  computed: { 
    blockClass() {
      return this.entry.answer ? "answer-style" : "question-style" 
    },
    date() {
      return (this.entry.date || '').replace('T', ' ').replace(/:00$/, '') 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

.question-style, .question-style a {
  margin-bottom: 15px !important;
  border-radius: 10px !important;
  color:var(--user-question-color)  !important;
  border-color:var(--user-question-color);
  background-color: var(--user-question-background);
}

.answer-style, .answer-style a {
  border-radius: 10px !important;
  color:var(--user-answer-color) !important;
  border-color:var(--user-answer-color);
  background-color:var(--user-answer-background);
}

.unembedded {
  margin: 5px 6px;
  padding: 2px 7px;
}

.group-style {
  border-radius: 10px !important;
  border-color: #333 !important;
  padding: 6px;
  border-width: 1px  !important;
  background-color: var(--user-group-background);
  margin: 10px 6px;
}


</style>
