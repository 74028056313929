<template>
  <div>
    <b-button class="m-2 tabs" :variant="buttonVariant" @click="() => tab='main'">Read</b-button>
    <b-button class="m-2 tabs" :variant="buttonVariant" @click="post">Post</b-button>
    <b-button class="m-2 tabs" :variant="buttonVariant" @click="() => tab='about'">About</b-button>
    <b-button class="m-2 tabs" :variant="buttonVariant" @click="oldsite">Old site</b-button>

    <!-- Theme radio buttons -->
    <span>
      <b-form-group class="body-elem" v-slot="{ ariaDescribedby } " style="display: inline; margin-bottom: -20px;margin-left: 20px">
        <b-form-radio 
          size="sm"
          class="body-elem" 
          v-model="value.darkTheme" 
          :aria-describedby="ariaDescribedby" 
          :value="true" 
          style="display: block;">Dark
        </b-form-radio>
        <b-form-radio 
          size="sm"
          class="body-elem" 
          v-model="value.darkTheme" 
          :aria-describedby="ariaDescribedby" 
          :value="false" 
          style="display: block;">Light
        </b-form-radio>
      </b-form-group>
    </span>

    <p>
    <div v-show="tab=='main'">
      <keep-alive>
        <blog-reader 
          :darkTheme="value.darkTheme"
          @postingLinkFound="url => postingLink = url
          "/>
      </keep-alive>
     </div>

    <div v-show="tab=='about'">
      <about :postingLink="postingLink"/>
    </div>
  </div>
</template>

<script>
import About from './About.vue'
import BlogReader from './BlogReader.vue'

export default {
  name: 'Top',
  components: { BlogReader, About },
  props: {
    value: {
      type: Object,
      default: Function,
    }
  },
  data() {
    return {
      tab: 'main',
      postingLink: '',
    }
  },
  computed: {
   buttonVariant() {
      return this.value.darkTheme ? "outline-success" : "outline-warning"
    } 
  },
  watch: {
    value(newValue) {
      console.log('watch')
      this.$emit('input', newValue)
    }
  },
  methods: {
    oldsite() {
      window.open('http://old.rossilivecat.com', '_blank')
    },
    post() {
      window.open(this.postingLink, '_blank')
    }
  }

}
</script>

<style lang="less">
.tabs {
  color: var(--user-widget-color);
  border: var(--user-widget-border);
  font-size: 12px;
  padding: 0px 10px;
  margin: 0px 20px !important;
}

</style>
