import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

import axios from 'axios'
import AsyncComputed from 'vue-async-computed'

Vue.filter('formatDate', function(value) {
  if (value) {
    return value.toISOString().split('T')[0]
  }
})

Vue.prototype.$http = axios
Vue.use(AsyncComputed)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
